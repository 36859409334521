img {
    height: 100%;
    width: 100%;
    padding: 0;
    cursor: pointer;
}

.resume-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50vh;
    border-radius: 26px;
    background-color: rgba(255, 255, 255, 0.973);
    z-index: 2;
    height: 575px;
    max-height: 100%;
    width: 675px;
    max-width: 100%;
    overflow: auto;
  }

  .resume-guts {
    overflow: auto;
  }

.resume-button {
    border: none;
    font-size: 15px;
    font-weight: bolder;
    border-radius: 6px;
    cursor: pointer;
    padding: 13px;
  }