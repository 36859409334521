.about-button {
  border: none;
  font-size: 15px;
  font-weight: bolder;
  border-radius: 6px;
  cursor: pointer;
  }

.about-img {
    max-height: 200px;
    height: 100%;
    max-width: 200px;
    width: 100%;
    border-radius: 100px;
    margin-top: 30px;
    border: 1px solid white;
}

#about-h1 {
  font-size: 28px;
  padding-bottom: 0px;
  padding-top: 20px;
  color: black;
}

.where-im-at {
  list-style: none;
  text-align: left;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

#location-title {
  font-weight: bold;
}

.places {
  padding-bottom: 10px;
  -webkit-justify-content: center;
          justify-content: center;
  max-width: 700px;
  width: 100%;
  line-height: 1.5em;
}

#places-h1 {
  font-size: 28px;
  color:  black;
  padding-top: 10px;
  padding-bottom: 0px;
}

#place-name {
  font-weight: bold;
  text-align: left;
}

#place {
  text-align: left;
}

.all-places {
  list-style: none;
  list-style: upper-roman;
}

#click-anywhere {
  padding-top: 25px;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  color: rgb(31, 28, 27);
  display: none;
}

#about-me {
    padding-bottom: 10px;
    padding-top: 0px;
    text-align: left;
    max-width: 550px;
    margin: 0 auto;
    padding: 5px;
    width: 100%;
    line-height: 1.5em;
}

#about-me-sub {
  padding-bottom: 10px;
  padding-top: 0px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  line-height: 1.5em;
}

#about-me-sub-2 {
  padding-bottom: 10px;
  padding-top: 0px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  line-height: 1.5em;
}

#about-me-sub-3 {
  padding-bottom: 10px;
  padding-top: 0px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  line-height: 1.5em;
}

#countries {
  padding-bottom: 10px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  line-height: 1.5em;
  width: 100%;
  max-width: 550px;
}

#location {
  padding-bottom: 10px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  line-height: 1.5em;
  width: 100%;
  max-width: 550px;
}

#location-h1 {
  font-size: 28px;
}

#location-sub {
  padding-bottom: 10px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  line-height: 1.5em;
  width: 100%;
  max-width: 550px;
}

#location-sub-2 {
  padding-bottom: 10px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  line-height: 1.5em;
  width: 100%;
  max-width: 550px;
}

#icon-female {
  margin: 20px;
}

#icon-pin {
  margin: 20px;
}

#icon-plane {
  margin: 20px;
  -webkit-transform: rotate(-35deg);
          transform: rotate(-35deg);
}

#connect {
    color: rgb(44, 39, 36, 0.808);
    font-weight: bold;
    margin: 0 auto;
    font-style: italic;
}

.all-places{
  list-style: none;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.about {
  background: white;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 40px;
  border: 1px solid grey;
  box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
  max-width: 800px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {

  .all-places {
    max-width: 325px;
    margin-left: 0;
    text-align: left;
  }

  .where-im-at {
    max-width: 325px;
    margin-left: 0;
    text-align: left;
  }
  
  #about-me {
    max-width: 325px;
    text-align: left;
  }

  #about-me-sub {
    max-width: 325px;
    text-align: left;
  }

  #about-me-sub-2 {
    max-width: 325px;
    text-align: left;
  }

  #about-me-sub-3 {
    max-width: 325px;
    text-align: left;
  }

  #location {
    max-width: 325px;
    text-align: left;
  }

  #location-sub {
    max-width: 325px;
    text-align: left;
  }

  #location-sub-2 {
    max-width: 325px;
    text-align: left;
  }

  #countries {
    max-width: 325px;
    text-align: left;
    margin-bottom: 15px;
  }
}


.tech-section {
  background: rgb(0, 0, 0);
  -webkit-justify-content: center;
          justify-content: center;
  margin: 40px;
  border: 1px solid grey;
  box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
  max-width: 800px;
  margin: 0 auto;
}

.all-skills{
    list-style: upper-roman;
    display: -webkit-flex;
    display: flex;
    margin: 0 auto;
    padding-bottom: 10px;
    -webkit-justify-content: center;
            justify-content: center;
    max-width: 700px;
    width: 100%;
    line-height: 1.5em;
}
  
#skill-name {
    font-weight: bold;
}

#skill {
    font-size: 16px;
    max-width: 150px;
}

.skills {
    margin-top: -15px;
    text-align: left;
    color: whitesmoke;
}
  
#skills-h1 {
    font-size: 28px;
    color:  whitesmoke;
    padding-top: 10px;
    padding-bottom: 0px;
}

@media screen and (max-width: 600px) {
    .all-skills {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap;
      margin-left: -5%;
    }
}
.contact-button {
    border: none;
    font-size: 15px;
    font-weight: bolder;
    border-radius: 6px;
    cursor: pointer;
  } 

.contact-wrapper {
  background: white;
  -webkit-justify-content: center;
  justify-content: center;
  border: 1px solid grey;
  border-radius: 5px;
  box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;
}

#contact-h1 {
 color:#000000;
 padding: 10px;
 font-size: 40px;
 letter-spacing: 2px;
}

.about-img {
    max-height: 200px;
    height: 100%;
    max-width: 200px;
    width: 100%;
    border-radius: 95px;
}

#click-anywhere {
  padding-top: 25px;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  color: rgb(31, 28, 27);
  display: none;
}

#connect {
    color: rgb(44, 39, 36, 0.808);
    font-weight: bold;
    margin: 0 auto;
    font-style: italic;
}

.contact-links {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    padding: 10px;
    padding-top: 0px;
    margin-top: 0px;
    list-style: none;
    font-size: 20px;
    cursor: pointer;
}

#contact-li {
    padding: 15px;
}



img {
    height: 100%;
    width: 100%;
    padding: 0;
    cursor: pointer;
}

.resume-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    top: 50vh;
    border-radius: 26px;
    background-color: rgba(255, 255, 255, 0.973);
    z-index: 2;
    height: 575px;
    max-height: 100%;
    width: 675px;
    max-width: 100%;
    overflow: auto;
  }

  .resume-guts {
    overflow: auto;
  }

.resume-button {
    border: none;
    font-size: 15px;
    font-weight: bolder;
    border-radius: 6px;
    cursor: pointer;
    padding: 13px;
  }
#menuToggle
{
  /* display: block; */
  position: relative;
  width: 150px;
  max-width: 100%;
  top: 22px;
  left: 10px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  -moz-user-select: none;
   -ms-user-select: none;
       user-select: none;
}

#menuToggle input
{
  /* display: block; */
  width: 30px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  
  background: #f4f4f4;
  border-radius: 3px;
  z-index: 1;
  
  -webkit-transform-origin: 4px 0px;
  
          transform-origin: 4px 0px;
  
  -webkit-transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease,
              -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menuToggle span:first-child
{
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  -webkit-transform-origin: 0% 100%;
          transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  -webkit-transform: rotate(45deg) translate(-2px, -1px);
          transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  -webkit-transform: rotate(0deg) scale(0.2, 0.2);
          transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  -webkit-transform: rotate(-45deg) translate(0, -1px);
          transform: rotate(-45deg) translate(0, -1px);
}

#menu {
    position: absolute;
    width: 375px;
    height: 278px;
    margin: -100px 0 0 -50px;
    padding-top: 100px;
    background: #000000de;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

/*slide it in from the left*/
#menuToggle input:checked ~ ul
{
  -webkit-transform: none;
          transform: none;
}

  @media screen and (max-width: 600px) {
    .nav {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.404);
    }
  } 
#portfolio-wrapper {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 50px;
    list-style: none;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 15px;
}

.portfolio-button {
    border: none;
    font-size: 15px;
    font-weight: bolder;
    border-radius: 6px;
    cursor: pointer;
  } 

.row {
    padding: 0 4px;
    list-style: none;
}

#portfolio-h1 {
    padding-top: 45px;
    font-size: 36px;
}

#i img {
    margin-top: -12px;
    -webkit-justify-content: center;
            justify-content: center;
}

#j img {
    margin-top: -12px;
    -webkit-justify-content: center;
            justify-content: center;
}

#x img {
    margin-top: -12px;
    -webkit-justify-content: center;
            justify-content: center;
}

#i {
    background: rgba(226, 205, 85, 0.116);
    box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
}

#j {
    background: rgba(255, 192, 203, 0.158);
    box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
}

#x {
    background: rgba(0, 77, 128, 0.11);
    box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
}

#app-name {
    margin-bottom: 30px;
}

#app-info {
    margin: 15px;
}

#app-type {
    margin-top: 8px;
    margin-bottom: 30px;
}

#app-tech {
    margin: 0 auto;
    text-align: left;
    line-height: 1.5em;
    padding-left: 10%;
    margin-bottom: 30px;
    max-width: 300px;
    margin-top: 8px;
}

#src {
    margin-top: 8px;
}

#app-description {
    margin: 0 auto;
    max-width: 600px;
    padding-top: 15px;
    line-height: 1.5em;
    text-align: left;
}

#app-description-sub {
    margin: 0 auto;
    max-width: 600px;
    padding-top: 15px;
    line-height: 1.5em;
    text-align: left;
}

.portfolio-item {
    list-style: none;
    -webkit-justify-content: center;
            justify-content: center;
    border: 1px solid grey;
    box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
    border-radius: 5px;
    background: white;
    margin-bottom: 15px;
}

img.item-img {
    display: block;
    max-width: 600px;
    max-height: 600px;
    height: 100%;
    width: 100%;
    margin: auto;
    cursor: pointer;
}

img:hover {
    -webkit-transform: scale (2.1);
            transform: scale (2.1);
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.719);
    box-shadow: 3px 6px rgba(128, 128, 128, 0.171);
    background: rgba(255, 255, 255, 0.192);
}

@media screen and (max-width: 800px) {
    .column {
      -webkit-flex: 50% 1;
              flex: 50% 1;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      -webkit-flex: 100% 1;
              flex: 100% 1;
      max-width: 100%;
    }
  }
.home {
    text-align: center;
    width: 100%;
  }
  
  .landing-top {
    position: relative;
    background:  -webkit-linear-gradient(
      rgba(255, 255, 255, 0), 
      rgba(255, 255, 255, 0.46)
    ), url(/static/media/wavybaby.d7a0b8a0.jpeg);
    background:  linear-gradient(
      rgba(255, 255, 255, 0), 
      rgba(255, 255, 255, 0.46)
    ), url(/static/media/wavybaby.d7a0b8a0.jpeg);
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    background-blend-mode: exclusion;
  }
  
  .landing-content {
    height: 87vh;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    color: #414141;
    box-shadow: 0px 2px 6px 1px rgb(54, 60, 61, 0.1);
    padding-bottom: 10px;
  }

  .landing-about {
    margin-top: 100px;
  }

  /* .nav button {
    padding: 10px;
    justify-content: space-between;
    margin: 10px;
  } */
  
  .landing-text {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    margin: auto;
  }
  
  .landing-text h1 {
    font-size: 52px;
    color:white;
    margin-bottom: 5px;
  }

.social {
  padding: 0px;
  font-size: 20px;
}

.social-links {
    list-style: none;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.social-links li {
  margin-left: 10%;
  margin-right: 25%;
  padding-top: 35px;
  font-size: 20px;
}
  
  #role {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 26px;
    letter-spacing: 1px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: #f5f5f5bf;
  }

  #role-description {
    color: #ffffff;
    font-style: italic;
    padding: 5px;
    font-size: 14px;
    margin-top: 0px;
    background: #000000b9;
    line-height: 1.5em;
    text-align: center;
  }

  .top-button {
    color: whitesmoke;
    background: rgba(0, 0, 0, 0.404);
    border: none;
    font-size: 15px;
    font-weight: bolder;
    padding: 5px 10px;
    border-radius: 6px;
    margin-top: 12px;
    margin-bottom: 30px;
    cursor: pointer;
  } 

  .top-button :hover {
    background-color: #ed84b7;
  }

  .top-button :active {
    background-color: #ed84b7;
    box-shadow: 0 1px #666;
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }

  @media screen and (max-width: 600px) {

    .body {
      max-width: 600px;
      width: 100%;
    }
  }
  
 
* {
  outline: 0;
}

body {
  font-family: 
    "rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F4F4;
  list-style: none;
  margin: auto auto;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  background-color: rgba(0, 0, 0, 0);
  color: whitesmoke;
}

button:hover {
  background-color: #ed84b7;
}

button:active {
  background-color: #ed84b7;
  box-shadow: 0 1px #666;
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
}

a {
  text-decoration: none;
  color: blue;
}

a:visited {
  color: purple;
}

@media screen and (max-width: 650px) {

  body {
    margin: 0 auto;
  }

  .home {
    width: 100%;
  }

} 
