#menuToggle
{
  /* display: block; */
  position: relative;
  width: 150px;
  max-width: 100%;
  top: 22px;
  left: 10px;
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle input
{
  /* display: block; */
  width: 30px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  
  background: #f4f4f4;
  border-radius: 3px;
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu {
    position: absolute;
    width: 375px;
    height: 278px;
    margin: -100px 0 0 -50px;
    padding-top: 100px;
    background: #000000de;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transition: -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

/*slide it in from the left*/
#menuToggle input:checked ~ ul
{
  transform: none;
}

  @media screen and (max-width: 600px) {
    .nav {
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.404);
    }
  } 