.contact-button {
    border: none;
    font-size: 15px;
    font-weight: bolder;
    border-radius: 6px;
    cursor: pointer;
  } 

.contact-wrapper {
  background: white;
  -webkit-justify-content: center;
  justify-content: center;
  border: 1px solid grey;
  border-radius: 5px;
  box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
  max-width: 500px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;
}

#contact-h1 {
 color:#000000;
 padding: 10px;
 font-size: 40px;
 letter-spacing: 2px;
}

.about-img {
    max-height: 200px;
    height: 100%;
    max-width: 200px;
    width: 100%;
    border-radius: 95px;
}

#click-anywhere {
  padding-top: 25px;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  color: rgb(31, 28, 27);
  display: none;
}

#connect {
    color: rgb(44, 39, 36, 0.808);
    font-weight: bold;
    margin: 0 auto;
    font-style: italic;
}

.contact-links {
    display: flex;
    justify-content: center;
    padding: 10px;
    padding-top: 0px;
    margin-top: 0px;
    list-style: none;
    font-size: 20px;
    cursor: pointer;
}

#contact-li {
    padding: 15px;
}


