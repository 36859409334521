#portfolio-wrapper {
    max-width: 800px;
    margin: 0 auto;
    margin-bottom: 50px;
    list-style: none;
    -webkit-justify-content: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 15px;
}

.portfolio-button {
    border: none;
    font-size: 15px;
    font-weight: bolder;
    border-radius: 6px;
    cursor: pointer;
  } 

.row {
    padding: 0 4px;
    list-style: none;
}

#portfolio-h1 {
    padding-top: 45px;
    font-size: 36px;
}

#i img {
    margin-top: -12px;
    justify-content: center;
}

#j img {
    margin-top: -12px;
    justify-content: center;
}

#x img {
    margin-top: -12px;
    justify-content: center;
}

#i {
    background: rgba(226, 205, 85, 0.116);
    box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
}

#j {
    background: rgba(255, 192, 203, 0.158);
    box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
}

#x {
    background: rgba(0, 77, 128, 0.11);
    box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
}

#app-name {
    margin-bottom: 30px;
}

#app-info {
    margin: 15px;
}

#app-type {
    margin-top: 8px;
    margin-bottom: 30px;
}

#app-tech {
    margin: 0 auto;
    text-align: left;
    line-height: 1.5em;
    padding-left: 10%;
    margin-bottom: 30px;
    max-width: 300px;
    margin-top: 8px;
}

#src {
    margin-top: 8px;
}

#app-description {
    margin: 0 auto;
    max-width: 600px;
    padding-top: 15px;
    line-height: 1.5em;
    text-align: left;
}

#app-description-sub {
    margin: 0 auto;
    max-width: 600px;
    padding-top: 15px;
    line-height: 1.5em;
    text-align: left;
}

.portfolio-item {
    list-style: none;
    justify-content: center;
    border: 1px solid grey;
    box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
    border-radius: 5px;
    background: white;
    margin-bottom: 15px;
}

img.item-img {
    display: block;
    max-width: 600px;
    max-height: 600px;
    height: 100%;
    width: 100%;
    margin: auto;
    cursor: pointer;
}

img:hover {
    transform: scale (2.1);
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.719);
    box-shadow: 3px 6px rgba(128, 128, 128, 0.171);
    background: rgba(255, 255, 255, 0.192);
}

@media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }