.home {
    text-align: center;
    width: 100%;
  }
  
  .landing-top {
    position: relative;
    background:  linear-gradient(
      rgba(255, 255, 255, 0), 
      rgba(255, 255, 255, 0.46)
    ), url('../assets/wavybaby.jpeg');
    max-width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size:cover;
    background-blend-mode: exclusion;
  }
  
  .landing-content {
    height: 87vh;
    align-items: center;
    display: flex;
    color: #414141;
    box-shadow: 0px 2px 6px 1px rgb(54, 60, 61, 0.1);
    padding-bottom: 10px;
  }

  .landing-about {
    margin-top: 100px;
  }

  /* .nav button {
    padding: 10px;
    justify-content: space-between;
    margin: 10px;
  } */
  
  .landing-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
  
  .landing-text h1 {
    font-size: 52px;
    color:white;
    margin-bottom: 5px;
  }

.social {
  padding: 0px;
  font-size: 20px;
}

.social-links {
    list-style: none;
    display: flex;
    justify-content: center;
}

.social-links li {
  margin-left: 10%;
  margin-right: 25%;
  padding-top: 35px;
  font-size: 20px;
}
  
  #role {
    margin-top: 0px;
    margin-bottom: 5px;
    font-size: 26px;
    letter-spacing: 1px;
    font-weight: bolder;
    letter-spacing: 3px;
    color: #f5f5f5bf;
  }

  #role-description {
    color: #ffffff;
    font-style: italic;
    padding: 5px;
    font-size: 14px;
    margin-top: 0px;
    background: #000000b9;
    line-height: 1.5em;
    text-align: center;
  }

  .top-button {
    color: whitesmoke;
    background: rgba(0, 0, 0, 0.404);
    border: none;
    font-size: 15px;
    font-weight: bolder;
    padding: 5px 10px;
    border-radius: 6px;
    margin-top: 12px;
    margin-bottom: 30px;
    cursor: pointer;
  } 

  .top-button :hover {
    background-color: #ed84b7;
  }

  .top-button :active {
    background-color: #ed84b7;
    box-shadow: 0 1px #666;
    transform: translateY(4px);
  }

  @media screen and (max-width: 600px) {

    .body {
      max-width: 600px;
      width: 100%;
    }
  }
  
 