* {
  outline: 0;
}

body {
  font-family: 
    "rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F4F4F4;
  list-style: none;
  margin: auto auto;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  background-color: rgba(0, 0, 0, 0);
  color: whitesmoke;
}

button:hover {
  background-color: #ed84b7;
}

button:active {
  background-color: #ed84b7;
  box-shadow: 0 1px #666;
  transform: translateY(4px);
}

a {
  text-decoration: none;
  color: blue;
}

a:visited {
  color: purple;
}

@media screen and (max-width: 650px) {

  body {
    margin: 0 auto;
  }

  .home {
    width: 100%;
  }

} 