.about-button {
  border: none;
  font-size: 15px;
  font-weight: bolder;
  border-radius: 6px;
  cursor: pointer;
  }

.about-img {
    max-height: 200px;
    height: 100%;
    max-width: 200px;
    width: 100%;
    border-radius: 100px;
    margin-top: 30px;
    border: 1px solid white;
}

#about-h1 {
  font-size: 28px;
  padding-bottom: 0px;
  padding-top: 20px;
  color: black;
}

.where-im-at {
  list-style: none;
  text-align: left;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

#location-title {
  font-weight: bold;
}

.places {
  padding-bottom: 10px;
  justify-content: center;
  max-width: 700px;
  width: 100%;
  line-height: 1.5em;
}

#places-h1 {
  font-size: 28px;
  color:  black;
  padding-top: 10px;
  padding-bottom: 0px;
}

#place-name {
  font-weight: bold;
  text-align: left;
}

#place {
  text-align: left;
}

.all-places {
  list-style: none;
  list-style: upper-roman;
}

#click-anywhere {
  padding-top: 25px;
  font-style: italic;
  font-size: 12px;
  font-weight: bold;
  color: rgb(31, 28, 27);
  display: none;
}

#about-me {
    padding-bottom: 10px;
    padding-top: 0px;
    text-align: left;
    max-width: 550px;
    margin: 0 auto;
    padding: 5px;
    width: 100%;
    line-height: 1.5em;
}

#about-me-sub {
  padding-bottom: 10px;
  padding-top: 0px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  line-height: 1.5em;
}

#about-me-sub-2 {
  padding-bottom: 10px;
  padding-top: 0px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  line-height: 1.5em;
}

#about-me-sub-3 {
  padding-bottom: 10px;
  padding-top: 0px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  width: 100%;
  line-height: 1.5em;
}

#countries {
  padding-bottom: 10px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  line-height: 1.5em;
  width: 100%;
  max-width: 550px;
}

#location {
  padding-bottom: 10px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  line-height: 1.5em;
  width: 100%;
  max-width: 550px;
}

#location-h1 {
  font-size: 28px;
}

#location-sub {
  padding-bottom: 10px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  line-height: 1.5em;
  width: 100%;
  max-width: 550px;
}

#location-sub-2 {
  padding-bottom: 10px;
  text-align: left;
  max-width: 550px;
  margin: 0 auto;
  padding: 5px;
  line-height: 1.5em;
  width: 100%;
  max-width: 550px;
}

#icon-female {
  margin: 20px;
}

#icon-pin {
  margin: 20px;
}

#icon-plane {
  margin: 20px;
  transform: rotate(-35deg);
}

#connect {
    color: rgb(44, 39, 36, 0.808);
    font-weight: bold;
    margin: 0 auto;
    font-style: italic;
}

.all-places{
  list-style: none;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.about {
  background: white;
  justify-content: center;
  margin: 40px;
  border: 1px solid grey;
  box-shadow: 3px 6px rgba(128, 128, 128, 0.308);
  max-width: 800px;
  margin: 0 auto;
}

@media screen and (max-width: 600px) {

  .all-places {
    max-width: 325px;
    margin-left: 0;
    text-align: left;
  }

  .where-im-at {
    max-width: 325px;
    margin-left: 0;
    text-align: left;
  }
  
  #about-me {
    max-width: 325px;
    text-align: left;
  }

  #about-me-sub {
    max-width: 325px;
    text-align: left;
  }

  #about-me-sub-2 {
    max-width: 325px;
    text-align: left;
  }

  #about-me-sub-3 {
    max-width: 325px;
    text-align: left;
  }

  #location {
    max-width: 325px;
    text-align: left;
  }

  #location-sub {
    max-width: 325px;
    text-align: left;
  }

  #location-sub-2 {
    max-width: 325px;
    text-align: left;
  }

  #countries {
    max-width: 325px;
    text-align: left;
    margin-bottom: 15px;
  }
}

